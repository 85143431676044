import { Card, CardContent, TextField, Typography } from "@mui/material";
import React, { useCallback } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Edit } from "@mui/icons-material";

function createData(
    name,
    calories,
    fat,
    carbs,
    protein,
  ) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, "Published", 24),
    createData('Ice cream sandwich', 237, "Published", 37),
    createData('Eclair', 262, "Published", 24),
    createData('Cupcake', 305, "Published", 67),
    createData('Gingerbread', 356, "Published", 49),
  ];

const DemoSample = () => {
    return (
        <div>
            <Card>
                <CardContent>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-center">
                            <Typography variant="subtitle1" className="!font-bold">Below Listed are the reports you created:</Typography>
                            <TextField id="outlined-basic" size="small" label="Search for..." variant="outlined" />
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Report Name</TableCell>
                                        <TableCell align="right">Upload on</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.calories}</TableCell>
                                            <TableCell align="right">{row.fat}</TableCell>
                                            <TableCell align="right">
                                                <Edit />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
};

export default DemoSample;
