// AddBlogPage.js
import { Container, Typography } from '@mui/material';
import AddBlogFormContainer from '../components/containers/AddBlogForm';
import RootLayout from '../components/RootLayout';

const AddBlogPage = () => {
  return (
    <RootLayout containerClasses={"!p-0"}>
      <Container maxWidth="xl" className="mt-12 p-6 border-2 border-blue-600 rounded-lg ">
        <Typography variant="h4" className="text-center mb-6 font-bold text-black">
          Add New Blog
        </Typography>
        <AddBlogFormContainer />
      </Container>
    </RootLayout>
  );
};

export default AddBlogPage;
