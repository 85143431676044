import { gql } from "@apollo/client";

const blogDetailsString = `
    slug 
    cover
    readTime
    title
    dateAdded
    subtitle
`;

const listBlogsQuery = gql`
query ListBlogs(
  $page: Int, 
  $limit: Int, 
  $search: String, 
  $sortBy: String, 
  $sortOrder: String
) {
  listBlogs(
    page: $page, 
    limit: $limit, 
    search: $search, 
    sortBy: $sortBy, 
    sortOrder: $sortOrder
  ) {
    ${blogDetailsString}
  }
}
`;

// Mutation for adding a blog
const addBlogMutation = gql`
  mutation AddBlog(
    $readTime: Int!,
    $title: String!,
    $cover: String!,
    $subtitle: String!,
    $content: String!
  ) {
    addBlog(
      input: {
        readTime: $readTime,
        title: $title,
        cover: $cover,
        subtitle: $subtitle,
        content: $content
      }
    ) {
      ${blogDetailsString}
    }
  }
`;

const updateBlogMutation = gql`
  mutation UpdateBlog(
    $blogId: String!,
    $title: String,
    $cover: String,
    $subtitle: String,
    $content: String
  ) {
    updateBlog(
      blogId: $blogId,
      input: {
        title: $title,
        cover: $cover,
        subtitle: $subtitle,
        content: $content
      }
    ) {
      ${blogDetailsString}
    }
  }
`;

export { listBlogsQuery, addBlogMutation, updateBlogMutation };
