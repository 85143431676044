import React from 'react';
import { Box, Container, Divider, Grid } from '@mui/material';
import AppNavBar from '../components/containers/AppNabBar';
import BlogLeftImageContainer from '../components/containers/BlogImageContainer';
import RightGridItemContainer from '../components/containers/RightGridContainer';
import ProductDetailContainer from '../components/containers/ProductDetailsContainer';
import CustomerReviews from '../components/containers/CustomerReviews';
import Footer from '../components/containers/BlogsFooter';
//import ShirtContainer from '../components/containers/ShirtContainer';

const reviewsData = [
  { initials: 'RK', name: 'Robert Karmazov', rating: 5, comment: 'This shirt is awesome!', date: '4 days ago', likes: 5, comments: 0 },

  { initials: 'JD', name: 'John Doe', rating: 5, comment: 'This shirt is comfortable and stylish.', date: '1 week ago', likes: 8, comments: 0 },
  
  { initials: 'SR', name: 'Sarang Raut', rating: 5, comment: 'Love the quality of this shirt!', date: '5 october 2024', likes: 20, comments: 2 },

  { initials: 'SB', name: 'Surbhi Bhut', rating: 5, comment: 'Love the quality of this shirt!', date: '6 weeks ago', likes: 10, comments: 2 },


  { initials: 'SB', name: 'Surbhi Bhut', rating: 5, comment: 'Love the quality of this shirt!', date: '6 weeks ago', likes: 10, comments: 2 },
  
];

const NewBlog = () => {
  return (
    <Box className="bg-white min-h-screen">
      <AppNavBar />
      
      <Container maxWidth={false} className="py-8 min-h-screen mt-0.5">
        <Grid container className="h-full">
          {/* Left Grid */}
          <Grid item xs={12} md={8} lg={8} className="overflow-y-auto max-h-screen">
            <BlogLeftImageContainer />
          </Grid>

          {/* Right Grid */}
          <Grid item xs={12} md={4} lg={4} className="relative md:sticky top-0 self-start w-full h-full">
            <RightGridItemContainer/>
          </Grid>
        </Grid>

        <Grid container spacing={4} className="h-full">
          <Grid item xs={12} md={12} lg={12}>
            <ProductDetailContainer/>
          </Grid>
        </Grid>
      </Container>
      
      <Divider orientation='horizontal' className='mx-4' />

      <CustomerReviews reviews={reviewsData} />
      <Footer />
    </Box>
  );
};

export default NewBlog;
