import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import ImageAtom from '../atoms/BlogImages';
import ImageGrid from '../molecules/BlogImageGrid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const BlogLeftImageContainer = () => {
  const images = [
    {
      src: "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_5.avif%3Falt%3Dmedia%26token%3De225fc77-8b43-455e-a88a-77bf9b4453d9&w=384&q=60",
      alt: "Shirt 1"
    },
    {
      src: "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_4.avif%3Falt%3Dmedia%26token%3D6b916c1e-193b-49fa-b932-a1a5c448dd46&w=384&q=60",
      alt: "Shirt 2"
    },
    {
      src: "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_1.avif%3Falt%3Dmedia%26token%3D986971d0-292d-46d8-b964-068ba8140dfa&w=384&q=60",
      alt: "Shirt 3"
    },
    {
      src: "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_1.avif%3Falt%3Dmedia%26token%3D986971d0-292d-46d8-b964-068ba8140dfa&w=384&q=60",
      alt: "Shirt 4"
    },
    {
      src: "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_6.avif%3Falt%3Dmedia%26token%3Df2d33408-51bc-49df-a991-d376830bbd98&w=384&q=60",
      alt: "Shirt 5"
    },
    {
      src: "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_2.avif%3Falt%3Dmedia%26token%3D39d241b0-8d57-46dc-9739-500adf60724a&w=384&q=60",
      alt: "Shirt 6"
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Use useMediaQuery to determine if the view is mobile
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box className="border border-gray-300 bg-white h-full w-full relative">
      {isMobile ? (
        <>
          <ImageAtom src={images[currentIndex].src} alt={images[currentIndex].alt} />
          <button
            onClick={() => setCurrentIndex((currentIndex - 1 + images.length) % images.length)}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded shadow hover:bg-gray-200"
          >
            <ArrowBackIcon/>
          </button>
          <button
            onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded shadow hover:bg-gray-200"
          >
            <ArrowForwardIcon/>
          </button>
        </>
      ) : (
        <ImageGrid images={images} /> // Use the ImageGrid component here for desktop
      )}
    </Box>
  );
};

export default BlogLeftImageContainer;