import React from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ rating }) => {
    return (
        <div className="flex items-center">
            {[...Array(5)].map((_, index) => {
                if (index < Math.floor(rating)) {
                    return <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-500" />;
                } else if (index === Math.floor(rating) && rating % 1 !== 0) {
                    return <FontAwesomeIcon key={index} icon={faStarHalfAlt} className="text-yellow-500" />;
                }
                return <FontAwesomeIcon key={index} icon={faStar} className="text-gray-300" />;
            })}
            <Typography className="ml-2 text-gray-700">{rating}</Typography>
        </div>
    );
};

export default StarRating;
