import InputAtom from "../../components/atoms/AddBlogInputField";
import TextAreaAtom from "../../components/atoms/AddBlogTextArea";
import { useFormContext } from "react-hook-form";
import { Grid } from '@mui/material';

const FormFields = ({ errors }) => { // Accept errors as prop
  const { register } = useFormContext();

  return (
    <Grid container spacing={2} className="mt-4 mb-4">
      <Grid item xs={12} sm={6}>
        <InputAtom
          label="Image URL"
          name="imageUrl"
          register={register} // Pass register directly
          error={errors.imageUrl} // Pass error for this field
          validation={{ required: "Image URL is required" }} // Add validation here
        />
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <InputAtom
          label="Title"
          name="title"
          register={register} // Pass register directly
          error={errors.title} // Pass error for this field
          validation={{ required: "Title is required" }} // Add validation here
        />
      </Grid>

      <Grid item xs={12}>
        <TextAreaAtom
          label="Blog Overview"
          name="overview"
          register={register} // Pass register directly
          error={errors.overview} // Pass error for this field
          validation={{ required: "Overview is required" }} // Add validation here
        />
      </Grid>
    </Grid>
  );
};

export default FormFields;
