import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReadBlogContainer from "../components/containers/ReadBlogContainer";
import RootLayout from "../components/RootLayout";
import { Container, Typography, Button } from "@mui/material";

const AddBlogPage = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <RootLayout containerClasses={"!p-0"}>
      <Container
        maxWidth="xl"
        className="mt-12 p-6 border-2 border-blue-600 rounded-lg"
      >
        <Typography
          variant="h4"
          className="text-center mb-6 font-bold text-black"
        >
          Read Blog
        </Typography>
        
        {/* Button to navigate back to the Blog List */}
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/blog-list')} 
          className="mt-4 ml-6"
        >
          Back to Blog List
        </Button>
        
        <ReadBlogContainer />
      </Container>
    </RootLayout>
  );
};

export default AddBlogPage;
