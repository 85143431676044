import { Typography } from '@mui/material';

const TypographyLink = ({ href, children }) => (
  <Typography
    component="a"
    href={href}
    className="text-black text-lg font-bold font-medium mr-6 no-underline hover:underline "
  >
    {children}
  </Typography>
);

export default TypographyLink;
