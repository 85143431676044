// Organisms/AppNavBar.js
import { AppBar, Toolbar, Box } from '@mui/material';
import Logo from '../molecules/Logo';
import NavLinks from '../molecules/NavLinks';
import SearchBar from '../molecules/SearchBar';
import ProfileCart from '../molecules/ProfileCart';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';

const AppNavBar = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  const navLinks = (
    <div className="flex flex-col p-4 md:flex-row md:p-0">
      <NavLinks />
    </div>
  );

  return (
    <AppBar position="static" className="bg-white border-b border-gray-200 shadow-none">
      <Toolbar className="w-full flex items-center justify-between py-4 px-4">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className="md:hidden"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
          {navLinks}
        </Drawer>

        <Logo />

        {/* Hide on mobile view, show on PC view */}
        <div className="hidden md:flex px-5"> 
          <NavLinks />
        </div>

        <Box className="flex-grow" />

        <SearchBar />

        <ProfileCart />
      </Toolbar>
    </AppBar>
  );
};

export default AppNavBar;
