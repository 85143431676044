import React from 'react';

const CustomTypography = ({ variant, children, className }) => {
  const variants = {
    h4: "text-xl font-bold",
    h6: "text-lg font-semibold",
    body1: "text-base font-medium",
    h3: "text-2xl font-bold"
  };

  return (
    <p className={`${variants[variant]} ${className}`}>
      {children}
    </p>
  );
};

export default CustomTypography;
