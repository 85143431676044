import React from 'react';
import { Typography } from '@mui/material';

const TypographyAtom = ({ variant, children, className }) => {
  return (
    <Typography variant={variant} className={className}>
      {children}
    </Typography>
  );
};

export default TypographyAtom;
