import React from 'react';
import { Rating } from '@mui/material';

const RatingDisplay = ({ value }) => {
  return (
    <div className="flex items-center">
      <Rating name="no-value" value={value} readOnly />
      <span className="font-bold ml-1">{value}</span>
    </div>
  );
};

export default RatingDisplay;
