import React from 'react';
import { Grid } from '@mui/material';
import SizeOption from '../atoms/SizeOption';

const SizeSelection = ({ sizes = [] }) => {
  return (
    <Grid container spacing={2}>
      {sizes.map((size, index) => (
        <Grid item xs={2} key={index}>
          <SizeOption size={size} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SizeSelection;
