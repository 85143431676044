import React from "react";

const EmailFormat = () => {
  return (
    <>
      <table
        style={{
          width: "100%",
          margin: "0 auto",
          maxWidth: "600px",
          borderCollapse: "collapse",
          borderRadius: "4px",
        }}
      >
        <tbody>
          <tr style={{ padding: "20px", textAlign: "center", borderColor: "white", }}>
            <td style={{ padding: "0" }}>
              <table width="100%" style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "0" }}>
                      <table
                        width="100%"
                        style={{
                          borderRadius: "4px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "25px",
                                backgroundColor: "#ddd",
                              }}
                            >
                              <h1
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  margin: "0",
                                  fontSize: "24px",
                                  color: "#333333",
                                  paddingBottom: "10px",
                                }}
                              >
                                Your order is <br /> on its way
                              </h1>
                              <table
                                className="tracking"
                                width="100%"
                                style={{ background: "none" }}
                              >
                                <tbody>
                                  <tr style={{ verticalAlign: "top" }}>
                                    <td width="30px">
                                      <p className="status">
                                        <span
                                          style={{
                                            display: "inline-block",
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "100%",
                                            backgroundColor: "#f0b63a",
                                            color: "#fff",
                                            fontSize: "12px",
                                            lineHeight: "20px",
                                            alignItems: "center",
                                          }}
                                        >
                                          ✔
                                        </span>
                                      </p>
                                      <p
                                        className="date"
                                        style={{
                                          fontSize: "11px",
                                          lineHeight: "14px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <span style={{ display: "block" }}>
                                          Jul 5
                                        </span>
                                        <span style={{ display: "block" }}>
                                          Ordered
                                        </span>
                                      </p>
                                    </td>
                                    <td style={{ paddingTop: "10px" }}>
                                      <span
                                        style={{
                                          display: "block",
                                          borderTop: "2px solid #f0b63a",
                                        }}
                                      ></span>
                                    </td>
                                    <td width="30px">
                                      <p className="status">
                                        <span
                                          style={{
                                            display: "inline-block",
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "100%",
                                            backgroundColor: "#f0b63a",
                                            color: "#fff",
                                            fontSize: "12px",
                                            lineHeight: "20px",
                                            alignItems: "center",
                                          }}
                                        >
                                          ✔
                                        </span>
                                      </p>
                                      <p
                                        className="date"
                                        style={{
                                          fontSize: "11px",
                                          lineHeight: "14px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <span style={{ display: "block" }}>
                                          Jul 6
                                        </span>
                                        <span style={{ display: "block" }}>
                                          Shipped
                                        </span>
                                      </p>
                                    </td>
                                    <td style={{ paddingTop: "10px" }}>
                                      <span
                                        style={{
                                          display: "block",
                                          borderTop: "2px solid #ccc",
                                        }}
                                      ></span>
                                    </td>
                                    <td width="30px">
                                      <p className="status">
                                        <span
                                          style={{
                                            display: "inline-block",
                                            width: "16px",
                                            height: "16px",
                                            border: "2px dashed #999999",
                                            borderRadius: "50%",
                                          }}
                                        ></span>
                                      </p>
                                      <p
                                        className="date"
                                        style={{
                                          fontSize: "11px",
                                          lineHeight: "14px",
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <span style={{ display: "block" }}>
                                          Jul 8
                                        </span>
                                        <span style={{ display: "block" }}>
                                          Expected Delivery
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr className="content">
                            <td style={{ padding: "25px", textAlign: "left" }}>
                              <p
                                style={{ fontSize: "14px", lineHeight: "18px" }}
                              >
                                Hi [Customer Name],
                              </p>
                              <p
                                style={{
                                  paddingTop: "10px",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                }}
                              >
                                Your order No:{" "}
                                <a
                                  href="#"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#f0b63a",
                                  }}
                                >
                                  34341
                                </a>{" "}
                                has shipped via DHL Express. Delivery on Monday.
                                Tracking number:{" "}
                                <a
                                  href="#"
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#f0b63a",
                                  }}
                                >
                                  CP625793090DE
                                </a>
                                .
                              </p>
                            </td>
                          </tr>
                          <tr className="shipment">
                            <td
                              style={{ padding: "0 25px", textAlign: "left" }}
                            >
                              <h2
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  lineHeight: "18px",
                                  color: "#000",
                                  paddingBottom: "10px",
                                }}
                              >
                                Shipment{" "}
                              </h2>
                              <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                  <tr
                                    style={{ borderBottom: "1px solid #eee" }}
                                  >
                                    <td width="20%" style={{ padding: "10px" }}>
                                      <img src="https://plus.unsplash.com/premium_photo-1678218594563-9fe0d16c6838?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c2hpcnR8ZW58MHx8MHx8fDA%3D" alt="Product Image" />
                                    </td>
                                    <td style={{ padding: "10px" }}>
                                      <h3
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          lineHeight: "18px",
                                          color: "#000",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        NYC Unisex Eco Raglan Hoodie
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "#000",
                                          lineHeight: "14px",
                                          paddingTop: "5px",
                                        }}
                                      >
                                        Light Pink • Medium • Quantity 1
                                      </p>
                                    </td>
                                  </tr>
                                  <tr
                                    style={{ borderBottom: "1px solid #eee" }}
                                  >
                                    <td style={{ padding: "10px" }}>
                                      <img src="https://plus.unsplash.com/premium_photo-1683140435505-afb6f1738d11?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2hpcnR8ZW58MHx8MHx8fDA%3D" alt="Product Image" />
                                    </td>
                                    <td style={{ padding: "10px" }}>
                                      <h3
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          lineHeight: "18px",
                                          color: "#000",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        NYC Unisex Eco Raglan Hoodie
                                      </h3>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "#000",
                                          lineHeight: "14px",
                                          paddingTop: "5px",
                                        }}
                                      >
                                        Light Pink • Medium • Quantity 1
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ padding: "20px 0" }}>
                              <a
                                href="#"
                                style={{
                                  backgroundColor: "#f0b63a",
                                  color: "#ffffff",
                                  padding: "10px 20px",
                                  textDecoration: "none",
                                  borderRadius: "30px",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  display: "inline-block",
                                  boxShadow: "0px 5px 7px #ccc",
                                  fontWeight: "600",
                                }}
                              >
                                Track shipment
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "25px", backgroundColor: "#f0b63a" }}>
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td>
                      <h2
                        style={{
                          fontWeight: "bold",
                          margin: "0",
                          fontSize: "24px",
                          color: "#333333",
                          paddingBottom: "10px",
                        }}
                      >
                        Customer information
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingBottom: "15px",
                      }}
                    >
                      Shipping address
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "#000",
                        fontWeight: "bold",
                        textAlign: "left",
                        paddingBottom: "15px",
                      }}
                    >
                      Billing address
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        lineHeight: "16px",
                        paddingBottom: "15px",
                      }}
                    >
                      <p style={{ paddingBottom: "3px" }}>[Customer Name]</p>
                      <p style={{ paddingBottom: "3px" }}>
                        123 Shipping Street
                      </p>
                      <p style={{ paddingBottom: "3px" }}>
                        Shippington, Kentucky K2P0SO
                      </p>
                      <p style={{ paddingBottom: "3px" }}>United States</p>
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        lineHeight: "16px",
                        paddingBottom: "15px",
                      }}
                    >
                      <p>Delight Confection</p>
                      <p>123 Billing Street</p>
                      <p>Billtown, Kentucky K2P0BO</p>
                      <p>United States</p>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "#000",
                        fontWeight: "bold",
                        paddingBottom: "15px",
                        textAlign: "left",
                      }}
                    >
                      Shipping method
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        lineHeight: "18px",
                        color: "#000",
                        fontWeight: "bold",
                        paddingBottom: "15px",
                        textAlign: "left",
                      }}
                    >
                      Payment method
                    </th>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        lineHeight: "14px",
                      }}
                    >
                      DHL Express
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        color: "#000",
                        lineHeight: "14px",
                      }}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/1024px-Mastercard-logo.svg.png"
                        alt="MasterCard Logo"
                        width="30"
                        height="20"
                        className="mastercard-logo"
                        style={{ verticalAlign: "middle" }}
                      />
                      <span
                        style={{
                          display: "inline",
                          verticalAlign: "middle",
                          paddingLeft: "5px",
                        }}
                      >
                        Master **** 6500
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EmailFormat;
