import { useMutation } from "@apollo/client";
import { addBlogMutation } from '../utils/graphqlQueries/blogQueries';
import { contentClient } from "../service/apolloClientService";

const useAddBlog = (blogToEdit) => {
    const [addBlog] = useMutation(addBlogMutation);

    const onSubmit = async (data, navigate, setError) => {
        try {
            console.log("Submitting blog data:", data);
            
            // Calculate readTime (for example, based on word count)
            const readTime = Math.ceil(data.content.split(' ').length / 200); // 200 words per minute

            const { data: result } = await addBlog({
                variables: {
                    readTime,
                    title: data.title,
                    cover: data.imageUrl,
                    subtitle: data.overview,
                    content: data.content,
                },
                client: contentClient,
            });

            // Handle successful submission
            console.log("Blog submitted successfully:", result);
            navigate("/blog-list"); // Navigate to the blog list page after submission
        } catch (error) {
            console.error("Error submitting blog:", error);
            setError("apiError", { message: error.message || "Error submitting blog." });
        }
    };

    return { onSubmit };
};

export default useAddBlog;
