import React from 'react';
import { Grid } from '@mui/material';
import ProductDetails from '../molecules/ProductDetails';
import CustomerReviews from '../molecules/CustomerReviewsType';

const ProductDetailContainer = () => {
  return (
    <Grid container width="100%">
      <Grid item xs={12} >
        <ProductDetails />
        <CustomerReviews display="flex" justifyContent="center" alignItems="center" size="grow"/>
      </Grid>
    </Grid>
  );
};

export default ProductDetailContainer;
