import React from 'react';
import CustomTypography from '../atoms/RightGridTypography';

const PriceDisplay = ({ price }) => {
  return (
    <CustomTypography variant="h3" className="font-bold mt-2">
      ₹{price}
    </CustomTypography>
  );
};

export default PriceDisplay;
