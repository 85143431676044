import React, { useState, useEffect} from "react";
import BlogList from "../molecules/BlogsList";
import useBlogs from "../../hooks/useListBlog";
import { contentClient } from "../../service/apolloClientService";
import { Box, LinearProgress, Button } from "@mui/material";

const BlogListContainer = () => {
    const [page, setPage] = useState(1);
    const [allBlogs, setAllBlogs] = useState([]);
    const { blogs, loading, error, fetchMore } = useBlogs(page, 10);

const loadMoreBlogs = async () => {
    const nextPage = page + 1;
    try {
        const result = await fetchMore({
            variables: { page: nextPage, limit: 10 }, 
            client: contentClient,
        });

        if (result.data.blogs.length > 0) {
            setAllBlogs((prevBlogs) => [...prevBlogs, ...result.data.blogs]);
            setPage(nextPage);
        }
    } catch (error) {
        console.error("Error fetching more blogs:", error);
    }
};

    
    

    useEffect(() => {
        if (blogs && blogs.length > 0 && allBlogs.length === 0) {
            setAllBlogs(blogs);
        }
    }, [blogs, allBlogs]);

    const renderBlogs = () => {
        if (loading && allBlogs.length === 0) {
            return <LinearProgress color="inherit" />;
        }

        if (error) {
            return <p>Error: {error.message}</p>;
        }

        if (allBlogs.length === 0) {
            return <p>No blogs found.</p>;
        }

        return (
            <>
                <Box className="overflow-auto" style={{ height: "calc(100vh - 150px)" }}>
                    <BlogList blogs={allBlogs} />
                </Box>
                <Box className="flex justify-center mt-4">
                    <Button onClick={loadMoreBlogs} variant="contained" color="primary">
                        Load More
                    </Button>
                </Box>
            </>
        );
    };

    return (
        <Box className="flex flex-col gap-4 h-[calc(100vh-64px)]">
            {renderBlogs()}
        </Box>
    );
};

export default BlogListContainer;

