import React from 'react';

const FigmaTemplate3 = () => {
  return (
    <div style={styles.pageContainer}>
      <div style={styles.card}>
        {/* Top Section */}
        <div style={styles.logoContainer}>
          {/* Logo Image */}
          <img src="https://www.lbrnd.com/_next/image/?url=%2Fassets%2Fimages%2Flogo-full.svg&w=384&q=60" alt="Logo" style={styles.logo} />
        </div>

        {/* Main Div (Pink Section) */}
        <div style={styles.mainDiv}>
          {/* Header Text */}
          <h1 style={styles.headerText}>Let’s get you signed in</h1>

          {/* Customer Name */}
          <p style={styles.customerName}>Hi [Customer Name],</p>

          <p style={styles.customerName}>
            We use this easy sign-in code so you don’t have to remember or type in yet another long password.
          </p>

          {/* Space */}
          <div style={styles.space}></div>

          {/* OTP Input */}
          <input
            type="text"
            placeholder="Enter OTP"
            maxLength="6"
            style={styles.otpInput}
          />

          {/* Space */}
          <div style={styles.space}></div>

          {/* Description */}
          <h3 style={styles.customerName}>Have questions or trouble logging in?</h3>
          <p style={styles.customerName}>
            Just reply to this email or contact{" "}
            <a href="mailto:support@appname.com">support@appname.com</a>
          </p>
        </div>

        {/* Bottom Section */}
        <div style={styles.bottomSection}>
          {/* Small Logo */}
          <img src="https://www.lbrnd.com/_next/image/?url=%2Fassets%2Fimages%2Flogo-full.svg&w=384&q=60" alt="Small Logo" style={styles.smallLogo} />

          {/* Social Media Links */}
          <div style={styles.socialLinks}>
            <span style={styles.linkItem}>Home</span> | <span style={styles.linkItem}>Twitter</span> | <span style={styles.linkItem}>Instagram</span>
          </div>

          {/* Underline */}
          <hr style={styles.underline} />

          {/* Two Description Lines */}
          <p style={styles.description}>If you have questions or need help, don't hesitate to contact our support team!</p>
          <p style={styles.description}>
            DEMOCO USA Inc, 4 World Trade Center, 150 Greenwich Street, 62nd Floor, New York, NY 10007, USA{" "}
          </p>

          {/* Footer Links */}
          <div style={styles.footerLinks}>
            <span style={styles.linkItem}>Terms & Conditions</span> | <span style={styles.linkItem}>Privacy Policy</span> | <span style={styles.linkItem}>Contact Us</span>
          </div>

          {/* Footer Description */}
          <p style={styles.footerDescription}>
            This message was sent to name@domain.com. If you don't want to receive these emails from DEMOCO in the future, you can edit your <b>profile</b> or <b>unsubscribe</b>.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f2f2f2',
  },
  card: {
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '0 15px',
  },
  logoContainer: {
    padding: '20px',
    backgroundColor: 'white',
  },
  logo: {
    width: '80px',
    marginBottom: '10px',
  },
  mainDiv: {
    padding: '20px 15px',
    backgroundColor: '#ffe6e6',
  },
  headerText: {
    margin: '0',
    fontSize: '30px', // Keep header size at 30px
    fontWeight: 'bold',
  },
  customerName: {
    margin: '10px 0',
    fontSize: '18px', // Set to 18px
    textAlign: 'left',
  },
  space: {
    height: '20px',
  },
  otpInput: {
    width: '100%',
    padding: '20px',
    fontSize: '20px', // Keep input size at 20px
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontWeight: 'bolder',
  },
  description: {
    marginTop: '20px',
    fontSize: '18px', // Set to 18px
    textAlign: 'left',
  },
  bottomSection: {
    padding: '20px 15px',
    textAlign: 'left', // Aligning content to the left
    backgroundColor: '#fff',
    color: '#666', // Faint text color
  },
  smallLogo: {
    width: '60px',
    marginBottom: '20px',
  },
  socialLinks: {
    marginBottom: '10px',
  },
  linkItem: {
    margin: '0 10px',
    display: 'inline-block',
    color: '#666', // Faint link color
    fontSize: '18px', // Set to 18px
  },
  underline: {
    width: '50%',
    margin: '10px 0',
    border: '1px solid #999', // Fainter underline
  },
  footerLinks: {
    marginTop: '20px',
  },
  footerDescription: {
    fontSize: '18px', // Set to 18px
    color: '#999999',
    textAlign: 'left',
  },
  '@media (maxWidth: 768px)': {
    card: {
      width: '100%',
      maxWidth: '90%',
    },
    headerText: {
      fontSize: '20px',
    },
    customerName: {
      fontSize: '18px',
    },
    button: {
      width: '100%',
    },
    underline: {
      width: '70%',
    },
    linkItem: {
      display: 'block',
      margin: '5px 0',
      fontSize: '18px', // Set to 18px
    },
  },
};

export default FigmaTemplate3;
