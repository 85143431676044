import { TextField } from '@mui/material';

const InputAtom = ({ label, name, register, error, validation }) => (
  <TextField
    fullWidth
    label={label}
    variant="outlined"
    {...register(name, validation)} // Register with validation
    error={!!error}
    helperText={error?.message}
    InputProps={{
      className: 'bg-white',
    }}
  />
);

export default InputAtom;
