import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Grid } from "@mui/material";
import BlogCard from "../containers/BlogCardsContainer";

const BlogList = ({ blogs, error }) => {
    const navigate = useNavigate();

    const handleEdit = (blog) => {
        navigate("/add-blog", { state: { blogToEdit: blog } });
    };

    const handleRead = (blog) => {
        navigate("/read-blog", { state: { blog } });
    };

    return (
        <Container maxWidth="xl" className="mt-12 p-6 bg-emerald-50">
            {error && <Typography color="error" className="text-center">{error}</Typography>}
            {blogs.length === 0 ? (
                <Typography variant="body1" className="text-center">No blogs available.</Typography>
            ) : (
                <Grid container spacing={4}>
                    {blogs.map((blog) => (
                        <Grid item xs={6} sm={6} md={4} lg={3} key={blog.id}>
                            <BlogCard blog={blog} onEdit={() => handleEdit(blog)} onRead={() => handleRead(blog)} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
};

export default BlogList;
