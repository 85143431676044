import { textAlign } from '@mui/system';
import React from 'react';

const FigmaTemplate2 = () => {
  return (
    <div style={styles.pageContainer}>
      <div style={styles.card}>
        {/* Top Section */}
        <div style={styles.logoContainer}>
          {/* Logo Image */}
          <img src="https://www.lbrnd.com/_next/image/?url=%2Fassets%2Fimages%2Flogo-full.svg&w=384&q=60" alt="Logo" style={styles.logo} />
        </div>

        {/* Main Div (Pink Section) */}
        <div style={styles.mainDiv}>
          {/* Header Text */}
          <h1 style={styles.headerText}>Nice to meet you!</h1>

          {/* Customer Name */}
          <p style={styles.customerName}>Hi [Customer Name],</p>

          {/* Space */}
          <div style={styles.space}></div>
          <div style={styles.space}></div>
          <div style={styles.space}></div>

          {/* Customer Message */}
          <p style={styles.customerName}>Thank you for shopping with us! Your Lbrnd order has been successfully delivered at the given address.</p>

          <h4 style={{ textAlign: 'left', fontWeight: 'bold'}}>Items</h4>

          {/* Shopping Items Section */}
          <div style={styles.shoppingItemsContainer}>
            {/* Item 1 */}
            <div style={styles.itemRow}>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ42pD8Dpz012EtAi3PrRwNDUSAC_sg2dUxXw&s" alt="Item 1" style={styles.itemImage} />
              <div style={styles.itemDetails}>
                <p style={styles.itemName}>MCM BagPack</p>
                <p style={styles.itemPrice}>$980.00</p>
              </div>
            </div>
            <div style={styles.itemAttributes}>
              <p style={styles.attribute}>Red</p>
              <p style={styles.attribute}>Medium</p>
              <p style={styles.attribute}>Quantity 1</p>
              <p style={styles.attribute}>Available</p>
            </div>

            <hr style={{ border: '1px solid white' }} />

            {/* Item 2 */}
            <div style={styles.itemRow}>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEAi5POBczfCWrRUyyUHulLbnNPlii3BMLeg&s" alt="Item 2" style={styles.itemImage} />
              <div style={styles.itemDetails}>
                <p style={styles.itemName}>MCM BagPack</p>
                <p style={styles.itemPrice}><span style={{ outline: '2px dashed black' }}>$900</span> $882.00</p>
              </div>
            </div>
            <div style={styles.itemAttributes}>
              <p style={styles.attribute}>Blue</p>
              <p style={styles.attribute}>Large</p>
              <p style={styles.attribute}>Quantity 1</p>
              <p style={styles.attribute}>Available</p>
            </div>

            <hr style={{ border: '1px solid white' }} />

            {/* Subtotal, Billing, Total, You Saved Section */}
            <div style={styles.priceSummary}>
              <p style={styles.summaryItem}>Subtotal: $1862.00</p>
              <p style={styles.summaryItem}>Billing: $30.00</p>
              <p style={styles.summaryItem}>Total: $1892.00</p>
              <p style={styles.summaryItem}>You Saved: $18.00</p>
            </div>
          </div>
        </div>

        <div style={styles.space}></div>

        {/* Customer Information Section */}
        <div style={styles.container}>
          <h2 style={styles.header}>Customer information</h2>

          <div style={styles.addressContainer}>
            <div style={styles.addressBlock}>
              <h4 style={styles.addressTitle}>Shipping address:</h4>
              <div style={styles.addressDetails}>
                <p style={styles.addressDetail}>[Customer Name], 123 Shipping Street, Shippington, Kentucky K2P0SO, United States</p>
              </div>
            </div>

            <div style={styles.addressBlock}>
              <h4 style={styles.addressTitle}>Billing address:</h4>
              <div style={styles.addressDetails}>
                <p style={styles.addressDetail}>Delight Confection, 123 Billing Street, Billtown, Kentucky K2P0BO, United States</p>
              </div>
            </div>
          </div>

          <div style={styles.methodContainer}>
            <div style={styles.methodBlock}>
              <h4 style={styles.methodTitle}>Shipping method:</h4>
              <p style={styles.methodDetail}>DHL Express</p>
            </div>

            <div style={styles.methodBlock}>
              <h4 style={styles.methodTitle}>Payment method:</h4>
              <div style={styles.paymentDetail}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/1024px-Mastercard-logo.svg.png"
                  alt="MasterCard Logo"
                  width="20"
                  height="20"
                  style={styles.logo}
                />
                <span style={styles.paymentText}>Master **** 6500</span>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div style={styles.bottomSection}>
          {/* Small Logo */}
          <img src="https://www.lbrnd.com/_next/image/?url=%2Fassets%2Fimages%2Flogo-full.svg&w=384&q=60" alt="Small Logo" style={styles.smallLogo} />

          {/* Social Media Links */}
          <div style={styles.socialLinks}>
            <span style={styles.linkItem}>Home</span> | <span style={styles.linkItem}>Twitter</span> | <span style={styles.linkItem}>Instagram</span>
          </div>

          {/* Underline */}
          <hr style={styles.underline} />

          {/* Two Description Lines */}
          <p style={styles.description}>If you have questions or need help, don't hesitate to contact our support team!</p>
          <p style={styles.description}>
            DEMOCO USA Inc, 4 World Trade Center, 150 Greenwich Street, 62nd Floor, New York, NY 10007, USA{" "}
          </p>

          {/* Footer Links */}
          <div style={styles.footerLinks}>
            <span style={styles.linkItem}>Terms & Conditions</span> | <span style={styles.linkItem}>Privacy Policy</span> | <span style={styles.linkItem}>Contact Us</span>
          </div>

          {/* Footer Description */}
          <p style={styles.footerDescription}>
            This message was sent to name@domain.com. If you don't want to receive these emails from DEMOCO in the future, you can edit your <b>profile</b> or <b>unsubscribe</b>.
          </p>
        </div>
      </div>
    </div>
  );
};

// Styles for the component
const styles = {
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f2f2f2',
  },
  card: {
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '0 15px',
  },
  logoContainer: {
    padding: '20px',
    backgroundColor: 'white',
  },
  logo: {
    width: '80px',
    marginBottom: '10px',
  },
  mainDiv: {
    padding: '20px 15px',
    backgroundColor: '#ffe6e6',
  },
  headerText: {
    margin: '0',
    fontSize: '25px',
    fontWeight: 'bold',
  },
  customerName: {
    margin: '10px 0',
    fontSize: '16px',
    textAlign: 'left',
  },
  space: {
    height: '20px',
  },
  shoppingItemsContainer: {
    margin: '20px 0',
  },
  itemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  itemImage: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
  itemDetails: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemName: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginRight: '20px',
  },
  itemPrice: {
    fontSize: '14px',
    color: '#333',
  },
  itemAttributes: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  attribute: {
    fontSize: '12px',
    color: '#333',
  },
  priceSummary: {
    fontSize: '12px',
    color: '#333',
    textAlign: 'right',
  },
  container: {
    padding: "20px",
    backgroundColor: "#f0b63a",
  },
  header: {
    fontWeight: "bold",
    margin: "0",
    fontSize: "24px",
    color: "#333333",
    paddingBottom: "10px",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "15px",
  },
  addressBlock: {
    flex: "1",
    marginRight: "10px",
  },
  addressTitle: {
    fontSize: "14px",
    lineHeight: "18px",
    color: "#000",
    fontWeight: "bold",
    paddingBottom: "3px",
    textAlign: 'left',
  },
  addressDetails: {
    textAlign: "left",
  },
  addressDetail: {
    fontSize: "12px",
  },
  methodContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px",
  },
  methodBlock: {
    flex: "1",
    marginRight: "10px",
  },
  methodTitle: {
    fontSize: "14px",
    lineHeight: "18px",
    color: "#000",
    fontWeight: "bold",
    paddingBottom: "5px",
    textAlign: 'left',
  },
  methodDetail: {
    fontSize: "12px",
    textAlign: "left",
  },
  paymentDetail: {
    display: "flex",
    alignItems: "center",
  },
  paymentText: {
    marginLeft: "10px",
    fontSize: "12px",
  },
  bottomSection: {
    padding: "10px",
    textAlign: "center",
  },
  smallLogo: {
    width: "50px",
    height: "20px",
    margin: "5px auto",
  },
  socialLinks: {
    fontSize: "12px",
    padding: "5px 0",
  },
  linkItem: {
    cursor: "pointer",
    color: "#0000EE",
  },
  underline: {
    borderColor: "lightgray",
  },
  description: {
    fontSize: "12px",
  },
  footerLinks: {
    fontSize: "12px",
    padding: "5px 0",
  },
  footerDescription: {
    fontSize: "10px",
    paddingBottom: "10px",
  },
};

export default FigmaTemplate2;
