
const parseData = (valueString, separator = ":") => {
    return valueString.split(separator);
}

const formattedDate = (timestamp) => {
    const numericTimestamp = Number(timestamp);
  
    const date = new Date(isNaN(numericTimestamp) ? timestamp : numericTimestamp);
  
    return !isNaN(date.getTime()) 
      ? date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) 
      : 'Invalid Date';
  };
  

export { formattedDate, parseData }