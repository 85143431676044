import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import FormFields from "../../components/molecules/AddBlogFormFields";
import MarkdownEditorAtom from "../../components/molecules/AddBlogMDE";
import ButtonsGroup from "../../components/molecules/AddBlogButtons";
import useAddBlog from "../../hooks/useAddBlog";

const AddBlogFormContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const blogToEdit = location.state?.blogToEdit || {}; 
  
  const methods = useForm({
      defaultValues: {
          imageUrl: blogToEdit.cover || "",
          title: blogToEdit.title || "",
          overview: blogToEdit.subtitle || "",
          content: blogToEdit.content || "",
      },
  });

  const { handleSubmit, setError, formState: { errors } } = methods;
  const { onSubmit } = useAddBlog(blogToEdit);

  return (
      <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data, navigate, setError))}>
              <FormFields errors={errors} />
              
              <MarkdownEditorAtom
                  value={methods.watch("content")}        
                  onChange={(val) => methods.setValue("content", val)} 
                  error={errors.content}                 
              />
              
              <ButtonsGroup
                  onCancel={() => navigate("/blog-list")}
                  onSave={handleSubmit((data) => onSubmit(data, navigate, setError))}
              />
              {errors.apiError && <p className="text-red-500">{errors.apiError.message}</p>}
          </form>
      </FormProvider>
  );
};


export default AddBlogFormContainer;
