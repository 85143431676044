import React from 'react';

const CustomButton = ({ onClick, children, variant, className }) => {
  const baseStyles = "w-full py-2 rounded transition-colors";
  const variantStyles = variant === 'contained' ? "bg-blue-500 text-white" : "border border-gray-300 text-gray-700";

  return (
    <button onClick={onClick} className={`${baseStyles} ${variantStyles} ${className}`}>
      {children}
    </button>
  );
};

export default CustomButton;
