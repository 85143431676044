import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import ImageModalMolecule from '../molecules/ImageModelMolecule';
import ImageAtom from '../atoms/ImageAtom';
import Button from '@mui/material/Button';

const ImageGalleryContainer = ({ images }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAllImages, setShowAllImages] = useState(false);

  const handleClickOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleToggleImages = () => {
    setShowAllImages((prev) => !prev);
  };

  // Display either 5 images or all depending on the showAllImages state
  const displayedImages = showAllImages ? images : images.slice(0, 4);

  const imageSize = { width: '150px', height: '150px' }; // Exact same size for all images

  return (
    <div>
      <Grid container spacing={2}>
        {displayedImages.map((imageUrl, index) => (
          <Grid item xs={12} md={2} lg={2} key={index}>
            <ImageAtom
              src={imageUrl}
              alt={`Image ${index}`}
              className="cursor-pointer"
              style={{
                ...imageSize, 
                objectFit: 'cover',
              }}
              onClick={() => handleClickOpen(imageUrl)}
            />
          </Grid>
        ))}

        {/* If there are more than 5 images, show a 5th image with remaining count */}
        {!showAllImages && images.length > 5 && (
          <Grid item xs={12} md={2} lg={2}> 
            <div
              className="relative cursor-pointer"
              style={imageSize}
              onClick={handleToggleImages}
            >
              <ImageAtom
                src={images[4]} 
                alt="More Images"
                className="w-full h-auto opacity-70"
                style={{
                  ...imageSize, // Apply consistent width and height
                  objectFit: 'cover',
                }}
              />
              <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white text-lg font-bold">
                +{images.length - 5} More
              </div>
            </div>
          </Grid>
        )}
      </Grid>

      {/* Toggle between showing "View Less" and "View More" */}
      {showAllImages && images.length > 5 && (
        <Button onClick={handleToggleImages}>View Less</Button>
      )}

      {/* Modal to display larger image */}
      {selectedImage && (
        <ImageModalMolecule
          open={open}
          handleClose={handleClose}
          imageUrl={selectedImage}
          modalStyle={{
            maxWidth: '80vw', // Set a larger width
            maxHeight: '80vh', // Set a larger height
            objectFit: 'contain', // Ensure the image fits properly within the modal
            margin: 'auto', // Center the modal
          }}
        />
      )}
    </div>
  );
};

export default ImageGalleryContainer;
