// atoms/ReviewTypeButton.js
import React from 'react';

const ReviewTypeButton = ({ label, count, className, isActive, onClick }) => {
  return (
    <button
      onClick={onClick} // Add onClick handler here
      className={`border-2 flex-grow min-w-5 text-transform-none py-2 px-4 rounded hover:bg-gray-300 ${isActive ? 'bg-black text-white border-black' : 'bg-white text-black border-gray-300'} ${className}`}
    >
      {`${label} (${count})`}
    </button>
  );
};

export default ReviewTypeButton;
