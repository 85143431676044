import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import ConnectedStoores from "../pages/ConnectedStores";
import Reminders from "../pages/Reminders";
import Login from "../pages/Login";
import OnboardStoreForm from "../pages/OnboardStoreForm";
import PageNotFound from "../pages/PageNotFound";
import ClientDetails from "../pages/ClientDetails";
import DemoSample from "../pages/DemoSample";
import EmailFormat from "../pages/EmailFormat";
import FigmaTemplate1 from "../pages/FigmaTemplate1";
import FigmaTemplate2 from "../pages/FigmaTemplate2";
import FigmaTemplate3 from "../pages/FigmaTemplate3";
import NewBlog from "../pages/NewBlogs";
import AddBlogPage from "../pages/AddBlogPage";
import BlogListPage from "../pages/BlogListPage";
import ReadBlogPage from "../pages/ReadBlogPage";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <Home />,
    element: <ConnectedStoores />,
  },
  {
    path: "/demo",
    // element: <Home />,
    element: <DemoSample />,
  },
  {
    path: "/connected-stores",
    element: <ConnectedStoores />,
  },
  {
    path: "/client-details",
    element: <ClientDetails />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/onboard-store-form",
    element: <OnboardStoreForm />,
  },
  {
    path: "/onboard-store-form/edit",
    element: <OnboardStoreForm />,
  },
  {
    path: "/reminders",
    element: <Reminders />,
  },
  {
    path: "/blogs",
    element: <NewBlog />,
  },
  {
    path: "/add-blog",
    element: <AddBlogPage />,
  },
  {
    path: "/blog-list",
    element: <BlogListPage />,
  },
  {
    path: "/read-blog", // For reading a specific blog
    element: <ReadBlogPage />,
  },
  {
    path: "email-format",
    element: <EmailFormat />,
  },
  {
    path: "figma-template-1",
    element: <FigmaTemplate1 />,
  },
  {
    path: "figma-template-2",
    element: <FigmaTemplate2 />,
  },
  {
    path: "figma-template-3",
    element: <FigmaTemplate3 />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;