import React, {useState} from 'react';
import { Button, Grid} from '@mui/material';
import ReviewCard from '../atoms/ReviewCard';
import ImageGalleryContainer from './ImageGalleryContainer';

const images = [
  "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_5.avif%3Falt%3Dmedia%26token%3De225fc77-8b43-455e-a88a-77bf9b4453d9&w=384&q=60",
  "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_4.avif%3Falt%3Dmedia%26token%3D6b916c1e-193b-49fa-b932-a1a5c448dd46&w=384&q=60",
  "https://images.pexels.com/photos/899357/pexels-photo-899357.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://images.pexels.com/photos/1042140/pexels-photo-1042140.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_1.avif%3Falt%3Dmedia%26token%3D986971d0-292d-46d8-b964-068ba8140dfa&w=384&q=60",
  "https://lbrnd.com/_next/image/?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fgenz-4e738.appspot.com%2Fo%2Fproducts%252Fmens%252Fshiner%2520satin%2520cotton%2520solid%2520shirt%252FLB_STNSHI_SRT_WHT_2.avif%3Falt%3Dmedia%26token%3D39d241b0-8d57-46dc-9739-500adf60724a&w=384&q=60",
];



const CustomerReviews = ({ reviews }) => {

    const [showAll, setShowAll] = useState(false); // State to control view more/less

    // Function to toggle show all reviews
    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

  return (
    <div className="w-full mx-auto p-6">
        <h2 className="text-3xl font-bold text-gray-700 mb-8">Customer Images & Reviews</h2>
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}> {/* Full width on mobile, half width on desktop */}
              <ImageGalleryContainer images={images} style={{ width: '100%' }} /> {/* Add style prop */}
            </Grid>

           {/* Right Section: Reviews */}
            <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                    {reviews.slice(0, showAll ? reviews.length : 3).map((review, index) => (
                    <Grid item xs={12} key={index}>
                        <ReviewCard review={review} />
                    </Grid>
                    ))}
                </Grid>
                <div className="flex justify-center mt-4">
                    <Button variant="outlined" onClick={toggleShowAll}>
                    {showAll ? 'View Less' : 'View More'}
                    </Button>
                </div>
                </Grid>
            </Grid>
    </div>
  );
};

export default CustomerReviews;
