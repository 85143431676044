import MDEditor from '@uiw/react-md-editor';
import { Typography, Box } from '@mui/material';

const MarkdownEditorAtom = ({ value, onChange, error }) => (
  <Box className="mt-2">
    <Typography variant="h6" className="text-black font-bold mb-4">Blog Content</Typography>
    
    <MDEditor
      value={value || ""}  
      onChange={onChange}  
      className="min-h-[400px] h-[800px] w-full"
    />
    
    {error && <Typography color="error">{error.message}</Typography>}
  </Box>
);

export default MarkdownEditorAtom;
