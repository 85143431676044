import React from 'react';
import { Box, Typography } from '@mui/material';
import StarRating from '../atoms/StarRatings';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const ReviewCard = ({ review }) => {
    return (
        <Box className="p-4 border rounded-lg">
            <div className="flex items-center mb-2">
                <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center text-gray-700 font-semibold">
                    {review.initials}
                </div>
                <div className="ml-2">
                    <Typography variant="h6" className="font-semibold">{review.name}</Typography>
                    <StarRating rating={review.rating} />
                </div>
            </div>
            <Typography className="text-gray-700 mb-4">{review.comment}</Typography>
            <div className="flex justify-between items-center text-gray-500">
                <span>{review.date}</span>
                <div className="flex items-center space-x-2">
                    <span className="flex items-center"><ThumbUpIcon /> {" "} {review.likes}</span>

                    <span className="flex items-center"><ThumbDownIcon/> {" "} {review.comments}</span>
                </div>
            </div>
        </Box>
    );
};

export default ReviewCard;
