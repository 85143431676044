// Molecules/ProfileCart.js
import { Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const ProfileCart = () => (
  <div className="flex items-center space-x-4 ml-6">
    <Typography
      component="a"
      href="#"
      className="flex items-center text-black no-underline hover:underline"
    >
      <AccountCircleIcon className="mr-2" /> Profile
    </Typography>
    <Typography
      component="a"
      href="#"
      className="flex items-center text-black no-underline hover:underline"
    >
      <ShoppingCartIcon className="mr-2" /> Cart
    </Typography>
  </div>
);

export default ProfileCart;
