import React from "react";

const BlogCard = ({ blog, onEdit, onRead }) => {
    return (
        <div className="mb-4 h-[400px] flex flex-col bg-white rounded-lg shadow-md">
            <div className="border border-2 h-[200px]">
                <img src={blog.cover} alt={blog.title} className="h-full w-full object-cover" />
            </div>
            <div className="p-4 grow">
                <h2 className="text-lg font-semibold">{blog.title}</h2>
                <p className="text-sm text-gray-600">{blog.readTime} min read</p>
            </div>
            <div className="mt-auto p-4">
                <button className="mr-4" onClick={onRead}>Read</button>
                <button onClick={onEdit}>Edit</button>
            </div>
        </div>
    );
};

export default BlogCard;
