import React from 'react';

const ImageAtom = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{ width: '100%', height: 'auto' }}
    />
  );
};

export default ImageAtom;
