import React from 'react';
import TypographyLink from '../atoms/TypographyLinks';

const NavLinks = () => {

  return (
    <div className="flex items-center">

      <div className="md:flex space-x-10">
        <TypographyLink href="#">MEN'S KURTA</TypographyLink>
        <TypographyLink href="#">PLAIN SHIRTS</TypographyLink>
        <TypographyLink href="#">PRINTED SHIRTS</TypographyLink>
      </div>
    </div>
  );
};

export default NavLinks;
