import React from 'react';
import { Grid } from '@mui/material';
import ImageAtom from '../atoms/BlogImages';

const ImageGrid = ({ images }) => {
  return (
    <Grid container spacing={2}>
      {images.map((image, index) => (
        <Grid item xs={12} md={6} lg={6} key={index}>
          <ImageAtom src={image.src} alt={image.alt} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGrid;
