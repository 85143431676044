// molecules/ReviewTypeSelector.js
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ReviewTypeButton from '../atoms/ReviewTypeButton';

const ReviewTypeSelector = ({ reviewCounts }) => {
  const [activeIndex, setActiveIndex] = useState(0); // Set the initial active index to 0

  const handleButtonClick = (index) => {
    setActiveIndex(index); // Update the active index on button click
  };

  return (
    <Grid item xs={12} md={12} className="flex gap-1 mt-2">
      {reviewCounts.map((reviewType, index) => (
        <ReviewTypeButton 
          key={index} 
          label={reviewType.label} 
          count={reviewType.count} 
          isActive={index === activeIndex} // Check if this button is active
          onClick={() => handleButtonClick(index)} // Handle click event
        />
      ))}
    </Grid>
  );
};

export default ReviewTypeSelector;
