// Molecules/SearchBar.js
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => (
  <TextField
    className="border border-gray-300 rounded font-bold w-full md:w-96"
    placeholder="Search…"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      classes: { input: 'font-bold' }, 
    }}
    variant="outlined"
    size="medium"
  />
);

export default SearchBar;
