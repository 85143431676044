import React from 'react';
import { Box } from '@mui/material';
import TypographyAtom from '../atoms/ProductDetailsTypo';
import DividerAtom from '../atoms/DividerAtom';

const ProductDetails = () => {
  return (
    <Box className="p-4 bg-white w-full">

      <DividerAtom className="my-4" />

      <h2 className="text-3xl font-bold text-gray-700 mb-4 mt-4">Product Details</h2>

      <TypographyAtom 
        variant="h6" 
        className="text-justify mb-4font-normal text-slate-500 pt-4" 
      >
        The LBRND Shiner Cotton Solid Shirt is crafted from high-quality
        shiner cotton, known for its subtle sheen, smooth texture, and
        luxurious feel. The shirt has a soft luster that adds a touch of
        sophistication to your formal or casual wardrobe. It’s perfect
        for those who appreciate a classic, polished look without
        compromising on comfort.
      </TypographyAtom>

      <TypographyAtom 
        variant="h6" 
        className="text-justify mb-4 text-slate-500 pt-4"
      >
        Description: <b>Men</b>
      </TypographyAtom>

    </Box>
  );
};

export default ProductDetails;
