import React from 'react';

const SizeOption = ({ size }) => {
  return (
    <div className="flex items-center justify-center border border-gray-300 rounded cursor-pointer hover:bg-gray-200 h-12">
      <span className="font-bold">{size}</span>
    </div>
  );
};

export default SizeOption;
