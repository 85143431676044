import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';

const ReadBlogPage = () => {
  const location = useLocation();
  const blog = location.state?.blog; // Get the blog data passed from BlogList

  if (!blog) {
    return (
      <Container>
        <Typography variant="h5" color="error" className="text-center">
          Blog not found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className="mt-12 p-6 bg-white border-2 border-gray-300 rounded-lg">
      <Typography variant="h4" className="font-bold mb-4">{blog.title}</Typography>
      <Box
        sx={{
          height: '600px', 
          width: '100%', 
          backgroundColor: '#f0f0f0', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          mb: 4,
        }}
      >
        {blog.imageUrl ? (
          <img
            src={blog.imageUrl}
            alt={blog.title}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover', // Maintain aspect ratio and cover the container
            }}
          />
        ) : (
          <Typography variant="caption" color="text.secondary">
            Image Not Available
          </Typography>
        )}
      </Box>
      <Typography variant="body1" className="mb-2"><strong>Overview:</strong> {blog.overview}</Typography>
      <Typography variant="body1"><strong>Content:</strong></Typography>
      <Typography variant="body1" className="whitespace-pre-line">{blog.content}</Typography>
    </Container>
  );
};

export default ReadBlogPage;
