import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import BlogListContainer from '../components/containers/BlogListContainer';
import RootLayout from '../components/RootLayout';

const BlogListPage = () => {
  return (
    <RootLayout containerClasses={"!p-0"}>
      <Container maxWidth="xl" className="border-2 border-blue-600 rounded-lg mt-12 p-6 bg-emerald-50">
        <Typography variant="h4" className="text-center mt-12 mb-6 font-bold text-black">
          Blog List
        </Typography>
        <Link to="/add-blog">
          <Button variant="contained" className="bg-blue-500 text-white mb-4 ml-4">
            Add New Blog
          </Button>
        </Link>
        <BlogListContainer />
      </Container>
    </RootLayout>
  );
};

export default BlogListPage;
