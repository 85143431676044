import React from "react";
import { Grid, Box } from "@mui/material";

const ColorBox = ({ color }) => {
  return (
    <Grid item xs={3}> {/* Adjusted column width to ensure boxes are visible */}
      <Box
        className="w-12 h-12 rounded cursor-pointer"
        style={{ backgroundColor: color }}
      />
    </Grid>
  );
};

export default ColorBox;
