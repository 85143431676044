import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ImageAtom from '../atoms/ImageAtom';

const ImageModalMolecule = ({ open, handleClose, imageUrl }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogContent className="relative">
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="absolute top-2 right-2"
        >
          <CloseIcon />
        </IconButton>
        {/* Display the Image */}
        <ImageAtom src={imageUrl} alt="Modal Image" className="w-full h-auto" />
      </DialogContent>
    </Dialog>
  );
};

export default ImageModalMolecule;
