import { useQuery } from "@apollo/client";
import { listBlogsQuery } from "../utils/graphqlQueries/blogQueries";
import { contentClient } from "../service/apolloClientService";

const useBlogs = (
  page = 1,
  limit = 10,
  search = "",
  sortBy = "dateAdded",
  sortOrder = "desc"
) => {
  const { loading, error, data, fetchMore } = useQuery(listBlogsQuery, {
    variables: {
      page,
      limit,
      search,
      sortBy,
      sortOrder,
    },
    client: contentClient,
  });

  const blogs = data ? data.listBlogs : [];

  return {
    loading,
    error,
    blogs,
    fetchMore,
  };
};

export default useBlogs;
