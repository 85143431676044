import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AddBusiness, Book, NotificationAdd, Notifications } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../assets/logo.svg";

const useStyle = makeStyles((theme) => ({
    container: {
        background: "#000",
        color: "#fff"
    },
    appbarRoot: {
    }
}))

const drawerWidth = 240;
const navItems = [
    { label: 'Dashboard', route: "/" },
    // { label: 'Reports & Analytics', route: "/reports-analytics" },
    { label: 'Connected Stores', route: "/connected-stores" },
    // { label: 'Inventory', route: "/products" },
    // { label: 'Orders', route: "/orders" },
    // { label: 'Fulfillment & Shipping', route: "/fulfilment" },
    // { label: 'Suppliers & Manufacturers ', route: "/suppliers-manufacturers" },
    // { label: 'Notifications & Alerts', route: "/alerts-notifications" },
    // { label: 'Customer Management', route: "/customers" },
    // { label: 'Settings', route: "/settings" },
];

const AppBarComponent = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyle();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    useEffect(() => {
        const userLoggedIn = localStorage.getItem("userLoggedIn");
        if (location.pathname !== "/login" && userLoggedIn !== "true") {
            navigate("/login");
        }
    }, [location.pathname, localStorage.getItem("userLoggedIn")])


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box className="flex justify-center py-2">
                <img src={logo} onClick={() => { navigate("/") }} alt="logo" style={{ height: "2rem", cursor: "pointer" }} />
            </Box>
            <List>
                {navItems.map((item) => (
                    <>
                        <ListItem key={item.label} disablePadding>
                            <ListItemButton onClick={() => navigate(item.route)} sx={{ textAlign: 'left' }}>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                        <Divider sx={{}} />
                    </>
                ))}
                <Box sx={{ px: 2, pt: 5 }}>
                    <Button onClick={() => {
                        localStorage.clear();
                        navigate("/login");
                    }} disableElevation fullWidth variant="contained" startIcon={<Notifications />}>
                        Logout
                    </Button>
                </Box>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" classes={{ root: classes.appbarRoot }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    // sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={logo} alt="logo" style={{ height: "2rem", cursor: "pointer" }} onClick={() => { navigate("/") }} />
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <Button onClick={() => navigate("/add-blog")} disableElevation style={{ background: "#fff", color: "#000" }} variant="contained" startIcon={<Book />}>
                            Add blog
                        </Button>
                        <Button onClick={() => navigate("/onboard-store-form")} disableElevation style={{ background: "#fff", color: "#000" }} variant="contained" startIcon={<AddBusiness />}>
                            Onboard Store
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Button onClick={() => navigate("/add-blog")} sx={{ mr: 2 }} disableElevation style={{ background: "#fff", color: "#000" }} variant="contained" startIcon={<Book />}>
                            Add Blog
                        </Button>
                        <Button onClick={() => navigate("/onboard-store-form")} sx={{ mr: 2 }} disableElevation style={{ background: "#fff", color: "#000" }} variant="contained" startIcon={<AddBusiness />}>
                            Onboard Store
                        </Button>
                        {/* <Button onClick={() => navigate("/reminders")} sx={{ mr: 2 }} disableElevation style={{ background: "#fff", color: "#000" }} variant="contained" startIcon={<Notifications />}>
                            Reminders
                        </Button> */}
                        <Button onClick={() => {
                            localStorage.clear();
                            navigate("/login");
                        }} disableElevation style={{ background: "#fff", color: "#000" }} variant="contained" startIcon={<Notifications />}>
                            Logout
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        // display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Toolbar />
        </Box>
    )
}
AppBarComponent.defaultProps = {
}

export default AppBarComponent;