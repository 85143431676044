import React from "react";
import { Grid, LinearProgress } from "@mui/material";
import TypographyAtom from "../atoms/ProductDetailsTypo";

const TotalRatings = () => {
  return (
    <div className="flex flex-col">
      <TypographyAtom variant="h6" className="mb-4">
        Total Ratings
      </TypographyAtom>

      {[5, 4, 3, 2, 1].map((star) => (
        <Grid
          container
          key={star}
          alignItems="center"
          className="mb-2 px-4" // Keep consistent padding for left and right
        >
          <Grid item className="w-8">
            <TypographyAtom variant="body1">{star} ★</TypographyAtom>
          </Grid>

          <Grid item xs className="mx-2"> {/* Use xs for full width */}
            <LinearProgress
              variant="determinate"
              value={Math.random() * 100}
              style={{
                height: "10px",
                borderRadius: "5px",
                backgroundColor: "black", 
                width: '100%', // Ensures the progress bar uses full width
              }}
              classes={{
                bar: {
                  backgroundColor: "black", // Color for the progress bar
                },
              }}
            />
          </Grid>

          <Grid item>
            <TypographyAtom variant="body2">123</TypographyAtom>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default TotalRatings;
