import React from "react";
import { Box, SvgIcon, Typography, Rating } from "@mui/material";

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const StarIcon = ({ filled }) => (
  <SvgIcon viewBox="0 0 22 20" className={`w-4 h-4 ${filled ? 'text-yellow-300' : 'text-gray-300'}`}>
    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
  </SvgIcon>
);

const RatingDisplay = () => {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  return (
    <Box className="flex items-center bg-gray-50 p-2 rounded-md shadow-sm">
      <Box className="flex items-center w-48">
        <Rating
          name="hover-feedback"
          value={value}
          precision={0.5}
          getLabelText={getLabelText}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} />}
        />
        {value !== null && (
          <Box className="ml-1 text-sm font-medium text-gray-600">{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>

      <Typography className="ml-1 text-sm font-medium text-gray-600">
        4.95 {" "}
      </Typography>

      <Typography className="ml-1 text-sm font-medium text-gray-600">
        out of 5
      </Typography>
    </Box>
  );
};

export default RatingDisplay;
