// ButtonsGroup.js
import { Button, Box } from '@mui/material';

const ButtonsGroup = ({ onCancel, onSave }) => (
  <Box className="flex justify-end gap-4 mt-4">
    <Button variant="contained" onClick={onCancel} className="bg-red-500 text-white">Cancel</Button>
    <Button variant="contained" onClick={onSave} className="bg-blue-500 text-white">Save</Button>
  </Box>
);

export default ButtonsGroup;
