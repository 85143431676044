import React, { useState } from "react";
import { Grid } from "@mui/material";
import CustomTypography from "../atoms/RightGridTypography";
import CustomButton from "../atoms/BlogRightGridButtons";
import QuantitySelect from "../atoms/QuantitySelect";
import SizeSelection from "../molecules/SizeSelection";
import ColorSelection from "../molecules/ColorSelection";
import PriceDisplay from "../molecules/PriceDisplay";
import SizeChartModal from "../molecules/SizeChartModel";
import RatingDisplay from "../atoms/RatingDisplay";


const RightGridItemContainer = ({ colors, sizes }) => {
  const [openSizeChart, setOpenSizeChart] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  return (
    <Grid
      item
      xs={12}
      md={4}
      className="relative md:sticky top-0 w-full h-full"
    >
      <Grid container className="border border-gray-300 bg-white p-4 md:p-5 w-full">
        {/* Product Title */}
        <Grid item xs={12}>
          <CustomTypography variant="h4" gutterBottom>
            Shiner Cotton Solid Shirt
          </CustomTypography>
        </Grid>

        {/* Rating and Reviews */}
        <Grid item xs={12} container alignItems="center">
          <Grid item>
            <RatingDisplay value={4.5} />
          </Grid>
          <Grid item>
            <div className="h-6 w-px bg-gray-400 mx-2" />
          </Grid>
          <Grid item>
            <CustomTypography variant="body1" className="font-bold">
              20 reviews
            </CustomTypography>
          </Grid>
        </Grid>

        {/* Price */}
        <Grid item xs={12}>
          <PriceDisplay price={900} />
        </Grid>

        {/* Color Selection */}
        <Grid item xs={12} className="mt-4">
          <CustomTypography variant="h6" className="mb-2">
            Color:
          </CustomTypography>
          <ColorSelection colors={colors || []} />
        </Grid>

        {/* Size Selection */}
        <Grid item xs={12} className="mt-4 mb-2">
          <CustomTypography variant="h6">Choose Size</CustomTypography>
          <SizeSelection sizes={sizes || []} />
        </Grid>

        {/* Size Chart Button */}
        <Grid item xs={12} className="mt-2">
          <CustomButton
            onClick={() => setOpenSizeChart(true)}
            variant="outlined"
          >
            Size Chart
          </CustomButton>
          <SizeChartModal
            open={openSizeChart}
            onClose={() => setOpenSizeChart(false)}
          />
        </Grid>

        {/* Quantity Selection */}
        <Grid item xs={12} className="mt-4 mb-2">
          <CustomTypography variant="h6">Quantity</CustomTypography>
          <QuantitySelect
            quantity={quantity}
            handleQuantityChange={handleQuantityChange}
          />
        </Grid>

        {/* Add to Cart Button */}
        <Grid item xs={12} className="mt-2">
          <CustomButton variant="contained">Add to Cart</CustomButton>
        </Grid>

        {/* Wishlist and Share Buttons */}
        <Grid item xs={12} className="mt-2">
          <div className="border-b mb-1" />
          <Grid container justifyContent="space-between">
            <Grid item xs={5}>
              <CustomButton variant="outlined" fullWidth>
                Wishlist
              </CustomButton>
            </Grid>
            <Grid item xs={5}>
              <CustomButton variant="outlined" fullWidth>
                Share
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RightGridItemContainer;
