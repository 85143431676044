import { Box } from '@mui/material';

const Logo = () => (
  <Box component="a" href="#" className="mr-14">
    <img
      src="https://www.lbrnd.com/_next/image/?url=%2Fassets%2Fimages%2Flogo-full.svg&w=384&q=60"
      alt="Logo"
      className="h-12"
    />
  </Box>
);

export default Logo;
