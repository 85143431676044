import React from "react";
import { Container, Grid, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-200 py-10 w-full">
      <Container maxWidth="xl" className="px-4">
        <Grid container spacing={6} className="flex-grow justify-between">
          {/* Need Help Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" className="font-bold mb-6 text-xl">
              Need Help
            </Typography>
            <div>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Contact us
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Track order
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Return & Refunds
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                FAQ's
              </Typography>
            </div>
          </Grid>

          {/* Company Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" className="font-bold mb-6 text-xl">
              Company
            </Typography>
            <div>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                About Us
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Brand Blog
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Collaboration
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Media
              </Typography>
            </div>
          </Grid>

          {/* More Info Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" className="font-bold mb-6 text-xl">
              More Info
            </Typography>
            <div>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Terms & Conditions
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Privacy Policy
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Shipping Policy
              </Typography>
              <Typography
                variant="h5"
                className="block mb-2 text-lg text-gray-200 cursor-pointer"
              >
                Cancellation & Refund Policy
              </Typography>
            </div>
          </Grid>

          {/* Location Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" className="font-bold mb-6 text-xl">
              Location
            </Typography>
            <Typography component="address" className="text-lg text-gray-200 cursor-pointer">
              B01 707 Shivalik Parisar Devguradiya, Near Energy Hospital Indore,
              India - 452016
              <br />
              Mail:{" "}
              <Typography
                variant="h5"
                className="text-lg text-gray-200 cursor-pointer"
              >
                sayhi@lbrnd.com
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <div className="text-center mt-10">
          <Typography variant="h5" className="text-lg">
            Copyright © 2024 LBRND. All rights reserved.
          </Typography>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
