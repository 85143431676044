import React from 'react';
import { Grid } from '@mui/material';
import TotalRatings from './TotalBlogProductRating';
import RatingDisplay from './BlogRatingDisplay';
import ReviewTypeSelector from './ReviewTypeSelector';

const reviewCounts = [
  { label: 'All', count: 0 },
  { label: 'Material', count: 0 },
  { label: 'Fitting', count: 0 },
  { label: 'Packaging', count: 0 },
];

const CustomerReviews = () => {
  return (
    <Grid container spacing={2} className="p-4 bg-white w-full">
      <Grid item xs={12}>
      <h2 className="text-3xl font-bold text-gray-700 mb-8">Customer Reviews</h2>
      </Grid>

      <Grid container item xs={12} spacing={2} alignItems="center">
        {/* Rating Display */}
        <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
          <RatingDisplay />
        </Grid>

        {/* Total Ratings */}
        <Grid item xs={12} md={4} className="p-10" justifyContent="center" alignItems="center">
          <TotalRatings />
        </Grid>

        {/* Review Type Selector */}
        <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
          <ReviewTypeSelector reviewCounts={reviewCounts} />
        </Grid>
      </Grid>

    </Grid>
  );
};

export default CustomerReviews;
