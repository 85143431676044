import React from "react";
import { Grid } from "@mui/material";
import ColorBox from "../atoms/ColorBox";

const ColorSelection = ({ colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00'] }) => {
  return (
    <Grid container spacing={2}>
      {colors.map((color, index) => (
        <ColorBox key={index} color={color} />
      ))}
    </Grid>
  );
};

export default ColorSelection;
