import React from 'react';
import { Box } from '@mui/material';
import CustomTypography from '../atoms/RightGridTypography';
import CustomButton from '../atoms/BlogRightGridButtons';

const SizeChartModal = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <Box className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <Box className="relative bg-white p-4 rounded shadow-lg">
        <CustomButton onClick={onClose} className="absolute top-2 right-2">Close</CustomButton>
        <CustomTypography variant="h6" className="mb-2">Size Chart</CustomTypography>
        {/* Add your size chart content here */}
      </Box>
    </Box>
  );
};

export default SizeChartModal;
