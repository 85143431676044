import React from 'react';

const QuantitySelect = ({ quantity, handleQuantityChange }) => {
  return (
    <select
      value={quantity}
      onChange={handleQuantityChange}
      className="border border-gray-300 rounded px-2 py-1 mb-2"
    >
      {[...Array(10).keys()].map((num) => (
        <option key={num + 1} value={num + 1}>
          {num + 1}
        </option>
      ))}
    </select>
  );
};

export default QuantitySelect;
